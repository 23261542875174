// * {
//     -moz-box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }

// body {
//     height: 100% !important;
//     width: 100%;
//     margin: 0;
//     background: #e5e6e4;
//     background-size: cover;
//     cursor: auto;
//     font-family: "DIN",arial,helvetica,sans-serif;
//     font-size: 16px;
//     font-weight: normal !important;
//     -webkit-font-smoothing: antialiased;
//     -moz-font-smoothing: none;
//     font-smoothing: antialiased;
// }

// ul, ol, dl {
//   list-style: none;
//   padding: 0;
//   margin: 0;
// }

.card-title {
    // font-size: 3.75em;
    text-transform: uppercase;
    //text-shadow: 3px 2px 0 #03393b;
    text-align: center;
}

// .renovation-module, body {
//     position: relative;
//     overflow: auto;
//     overflow-x: hidden;
// }

.headerbackground {
    background-image: url(./img/WebHeaderRoland1920.png);
    background-repeat: no-repeat;
}

.squarelistitems {
    list-style: square;
    padding-left:20px;
}

img.mugshot {
    max-width: 100%;
}

div#title {
    h1 {
        color: #fff;
        font-size:2.75rem;
    }
    h2 {
        color: #fff;
        font-size:2.25rem;
    }
    h3 {
        color: #fff;
        font-size:1.75rem;
    }
}

// div#about, div#about div, div#about h2, div#about h3 {
//     background-color: #999;
//     color: #000;
// }

.LinkedIn {
    width: 20em;
    display: block;
    margin: auto;
}

.LinkedInWhite {
    width: 15rem;
}

.skills_section {
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        list-style-type: none;
        width: 80%;
        margin: auto;
      }
}

// @media only screen and (min-width: 40.063em) {
//     .medium-3 {
//         width: 25%;
//     }
// }

// @media only screen and (min-width: 40.063em)
// {
//     .column, .columns {
//     position: relative;
//     padding-left: .9375em;
//     padding-right: .9375em;
//     float: left;
//     }
// }

// @media only screen and (min-width: 40.063em)
// {.medium-9 {
//     width: 75%;
// }
// }

// @media only screen and (min-width: 40.063em)
// {.column, .columns {
//     position: relative;
//     padding-left: .9375em;
//     padding-right: .9375em;
//     float: left;
// }
// }

// [class*=column]+[class*=column]:last-child {
//     float: right;
// }

// .row:after, .row:before {
//     content: " ";
//     display: table;
// }

// .row:after {
//     clear: both;
// }

// *, :after, :before {
//     box-sizing: border-box;
// }

// h3 {
//     font-size: 1.375rem;
// }
// h1, h2, h3, h4, h5, h6 {
//     color: #222;
//     text-rendering: optimizeLegibility;
//     margin-top: .2rem;
//     margin-bottom: .5rem;
//     line-height: 1.4;
// }

// p, p.lead {
//     line-height: 1.6;
// }

// p {
//     font-family: inherit;
//     font-weight: 400;
//     font-size: 1rem;
//     margin-bottom: 1.25rem;
//     text-rendering: optimizeLegibility;
// }

// @media only screen and (min-width: 40.063em)
// { h1 {
//     font-size: 2.75rem;
// }}

// @media only screen and (min-width: 40.063em)
// { h2 {
//     font-size: 2.3125rem;
// }}

// @media only screen and (min-width: 40.063em)
// { h3 {
//     font-size: 1.6875rem;
// }}

// @media only screen and (min-width: 40.063em)
// { h1, h2, h3, h4, h5, h6 {
//     line-height: 1.4;
// }}

// img {
//     display: inline-block;
//     vertical-align: middle;
// }

// img {
//     max-width: 100%;
//     height: auto;
//     -ms-interpolation-mode: bicubic;
// }

// blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
//     margin: 0;
//     padding: 0;
// }

/*section {
    height: 100vh;
    position: relative;
    color: #000000;
    text-align: center;
}*/

// div#contact {
//     min-height: 75px;
//     /*height: auto !important;*/
//     max-height: 150px;
// }

// div#work {
//     min-height: 75px;
//     height: auto !important;
// }

// div#about, div#about h2, div#about h3, div#about div {
//     background-color:#000000;
//     color:#FFFFFF;
// }

// div#title h2, div#title h3, div#title h1 {
//     color:#FFFFFF;
// }

.centerxy {
    position: absolute;
    top: 50%; 
    left: 50%; 
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
    /*width: 100%;*/
    text-align: center;
}

// .leftalign {
//     text-align:left;
// }

// .fullscreen {
//     width:100%;
//     height:100%;
// }

.space {
    height:25px;
}

.shadedborder {
    width:80vw;
    background: rgba(0, 0, 0, .5);
    border-radius: 25px;
}

.nomargin {
    margin: 0;
}

// #title h3 {
//     padding-left:10px;
// }

// #title {
//     height:100%;
// }

// #background {
//     background: url(/img/cityscape.jpg) center center / cover no-repeat;
// }

#title .centerxy {
    color:#FFFFFF; 
    
    /*width:50%;*/
}

/**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
@media screen and (device-aspect-ratio: 40/71) {
  #title {
    height: 500px !important;
  }
}

.textcenter {
    /*position: absolute;*/
    text-align: center;
}

.centerx {
    /*position: absolute; 
    left: 50%; 
    transform: translate(-50%); 
    width: 100%;*/
    text-align: center;
}

// .row {
//     max-width: 90em !important;
// }

.renovation-module, body {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, div, summary {
    display: block;
}

.page {
    position: relative;
}

// nav.navigation .navigation--list li a {
//     padding-bottom: 4px;
//     border-bottom: 1px solid transparent;
//     -moz-transition: border,0.5s,linear;
//     -o-transition: border,0.5s,linear;
//     -webkit-transition: border,0.5s,linear;
//     transition: border,0.5s,linear;
//     /*color: #000;*/
//     color: #fff;
//     letter-spacing: 0.1em;
// }

// nav.navigation .navigation--list li {
//     float: left;
//     margin-left: 20px;
//     font-family: "DIN-Bold",arial,helvetica,sans-serif;
//     font-size: 12px;
//     font-weight: normal !important;
//     text-transform: uppercase;
// }

.title
{
    padding-top:500px;
    background-position-y: center;
    background-position-x: center;
    a {
        color: white;
    }
}

// article, aside, main, nav, section {
//     display: block;
// }

.headerbox {
    position: absolute;
    top: 50%; 
    left: 50%; 
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
    /*width: 100%;*/
    /*text-align: center;*/
}

.titleheader {
    padding: 20px;
}

.clientimage {
    height: 4em;
    margin: auto;
}

.client-conent {
    max-width: 100%;
}

// .about_section {
//     padding-bottom: 20px;
// }

@media (max-width: 768px) {
    .title {
        /*background: url(../img/bg_title_mobile.jpg) 50% 0 no-repeat #167670;
        background-size: cover;*/
        padding-top: 750px;
        text-align: center;
    }
    .shadedborder {
        width: 100%;
        height: 700px;
    }
    // .headerbox {
    //     width:95%;
    // }
    .short_description {
        font-size:1.125em;
    }
    .mugshot_container {
        text-align:center;
    }
    .mugshot {
        width:50%;
    }
}



@media all and #{$screen-small} {
    .headerbackground {
        background-image: url(./img/WebHeaderMobile.png);
        // background-image: url(./img/WebHeader4-3.png);
        // margin-left: -500px;
        // margin-left: -15em;
    }
    .headerbox {
        width: 100%;
        // margin-top: -45em;
    }
    .mugshot_container {
        position: relative;
        // margin-left: 25%;
    }
    .titleDescription {
        position: relative;
        // left: 25%;
    }
    .mugshot {
        width:35%;
        // position: absolute;
    }

    .title {
        /*background: url(../img/bg_title_mobile.jpg) 50% 0 no-repeat #167670;
        background-size: cover;*/
        // padding-top: 600px;
        padding-top: 40em;
    }
    .shadedborder {
        height: 650px;
        // margin-left: 300px;
        // margin-left: 8em;
        border-radius: 0;
    }
    div#title {
        h1 {
            color: #fff;
            font-size:2.50rem;
        }
        h2 {
            color: #fff;
            font-size:2.0rem;
        }
        h3 {
            color: #fff;
            font-size:1.40rem;
        }
    }
    .skills_section {
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            list-style-type: none;
            width: 100%;
            margin: auto;
          }
    }

    .clientimage {
        width:95%;
        height: auto;
        margin: auto;
    }
}